import React, { useState, useRef, useEffect } from "react"
import Button from "./button"
import chevronIcon from "../images/common/chevron_white_down.svg"
import styles from "./dropdownButton.module.scss"

const DropdownButton = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", (e) => handleClick(e))
    return document.removeEventListener("mousedown", (e) => handleClick(e))
  }, [])

  return (
    <div className={styles.wrapper} ref={ref}>
      <Button
        onClick={() => setIsOpen((prev) => !prev)}
        icon={chevronIcon}
        classStyle={styles.button}
      >
        {label}
      </Button>
      <div className={`${styles.dropdownWrapper} ${isOpen ? styles.open : ""}`}>
        {children}
      </div>
    </div>
  )
}

export default DropdownButton
