import React from "react"

import Layout from "../components/layout"
import styles from "./downloads.module.scss"
import globalStyles from "../services/globalStyles.module.scss"
import lapMac from "../images/common/lap_mac.webp"
import lapWin from "../images/common/lap_windows.webp"
import mobileIphone from "../images/common/mobile_iphone.webp"
import mobileAndroid from "../images/common/mobile_android.webp"
import Button from "../components/button"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql } from "gatsby"
import DropdownButton from "../components/dropdownButton"

class DownloadsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      recommended: 0,
    }
  }

  render() {
    const {
      data: {
        texts: { _ },
      },
      pageContext,
    } = this.props
    const languageCode =
      pageContext && pageContext.lang ? pageContext.lang : "en"

    return (
      <Layout
        header={
          <Header
            num={1}
            translations={_}
            staticHeader
            languageCode={languageCode}
            hideNav
          />
        }
        translations={_}
        languageCode={languageCode}
      >
        <div
          className={[
            globalStyles.contentContainer200,
            globalStyles.headerPadding,
            globalStyles.column,
          ].join(" ")}
        >
          <h1 className={styles.header}>{_.downloads.heading}</h1>
          <p className={styles.paragraph}>{_.downloads.paragraph}</p>
          <div className={styles.downloadMenuContainer}>
            <div className={styles.downloadMenu}>
              <div
                className={styles.hover}
                /*onMouseOver={() => { this.state.recommended !== 1 && this.setState({ recommended: 1 }) }} className={this.state.recommended === 1 ? styles.active : null}*/
              >
                <img src={lapMac} />
                <DropdownButton label="MAC">
                  <ul className={styles.optionsList}>
                    <li>
                      <a href="https://xps-fast.s3-accelerate.amazonaws.com/installers/mac-m1/XPS.pkg">
                        macOS M (Apple Silicon)
                      </a>
                    </li>
                    <li>
                      <a href="https://xps-fast.s3-accelerate.amazonaws.com/installers/XPS.pkg">
                        macOS (Intel)
                      </a>
                    </li>
                    <li>
                      <a href="https://xps-fast.s3-accelerate.amazonaws.com/installers/XPS.zip">
                        macOS (10.11 or 10.12)
                      </a>
                    </li>
                    <li>
                      <a href="https://xps-fast.s3.amazonaws.com/installers/XPSJ8.pkg">
                        macOS (10.10 or older)
                      </a>
                    </li>
                  </ul>
                </DropdownButton>
              </div>
              <div
                className={styles.hover}
                /*onMouseOver={() => { this.state.recommended !== 2 && this.setState({ recommended: 2 }) }} className={this.state.recommended === 2 ? styles.active : null}*/
              >
                <img src={lapWin} />
                <DropdownButton label="WINDOWS">
                  <ul className={styles.optionsList}>
                    <li>
                      <a href="https://xps-fast.s3-accelerate.amazonaws.com/installers/XPS%2BNetwork.msi">
                        Windows 64-bit
                      </a>
                    </li>
                    <li>
                      <a href="https://xps-fast.s3-accelerate.amazonaws.com/installers/XPS%2BNetwork-J9.msi">
                        Windows 32-bit
                      </a>
                    </li>
                  </ul>
                </DropdownButton>
              </div>
              <div
                className={styles.hover}
                /*onMouseOver={() => { this.state.recommended !== 3 && this.setState({ recommended: 3 }) }} className={this.state.recommended === 3 ? styles.active : null}*/
              >
                <img src={mobileIphone} />
                <Button
                  anchor={
                    "https://itunes.apple.com/is/app/xps-network/id878771840?mt=8"
                  }
                  classStyle={styles.button}
                >
                  IOS
                </Button>
              </div>
              <div
                className={styles.hover}
                /*onMouseOver={() => { this.state.recommended !== 4 && this.setState({ recommended: 4 }) }} className={this.state.recommended === 4 ? styles.active : null}*/
              >
                <img src={mobileAndroid} />
                <Button
                  anchor={
                    "https://play.google.com/store/apps/details?id=com.xpsnetwork.android&hl=en"
                  }
                  classStyle={styles.button}
                >
                  ANDROID
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer translations={_} languageCode={languageCode} />
      </Layout>
    )
  }
}

export default DownloadsPage

export const query = graphql`
  query Downloads($langFile: String) {
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        downloads {
          heading
          paragraph
          download_for
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
  }
`
